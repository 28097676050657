import React from "react";
import styled from "styled-components";
import { Link } from 'gatsby'

const StyledProjectContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 30px;
    align-items: center;
    justify-items: start;
    padding-top: 1em;
`;

const ContainerLinkWrapper = styled(Link)`
    text-decoration: none;
`;

const ProjectImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 2px;
    box-shadow: 1px 2px 8px gray;
    transition-duration: 0.35s;
    ${ContainerLinkWrapper}:hover & {
        transform: scale(1.02);
    }
`;

const ProjectTitle = styled.h3`
    font-size: 4em;
    color: hsl(0, 0%, 20%);
    padding-bottom: 0em;
`;

const ProjectDescription = styled.p`
    color: hsl(0, 0%, 45%);
    padding-top: 1em;
    font-size: 1.25em;
    padding-bottom: 2em;
`;

const AnimatedLink = styled.p`
    color: hsl(0, 0%, 15%);
    font-weight: bold;
    display: inline;
    border-bottom: 2px solid transparent;
    ${ContainerLinkWrapper}:hover & {
        border-bottom: 4px solid hsl(153, 54%, 43%);
    }
    transition: border-bottom 0.35s ease 0s;
`;

export default function ProjectListItem({ href, img, title, children }) {
    return (
        <ContainerLinkWrapper to={href}>
            <StyledProjectContainer>
                <ProjectImage src={img} height="475" width="750" />
                <div>
                    <ProjectTitle>{title}</ProjectTitle>
                    <ProjectDescription>{children}</ProjectDescription>
                    <AnimatedLink>DETAILS</AnimatedLink>
                </div>
            </StyledProjectContainer>
        </ContainerLinkWrapper>
    );
}
