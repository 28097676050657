import React from "react";
import styled from "styled-components";

const StyledProjectContainer = styled.div`
    padding-top: 7em;
`;

const ProjectTitle = styled.h3`
    font-size: 4em;
    color: hsl(0, 0%, 20%);
    padding-bottom: 0em;
`;

const ProjectDescription = styled.p`
    color: hsl(0, 0%, 45%);
    padding-top: 1em;
    font-size: 1.25em;
    padding-bottom: 2em;
    max-width: 70ch;
`;

const ComingSoon = styled.p`
    color: hsl(0, 0%, 15%);
    font-weight: bold;
    display: inline;
    border-bottom: 2px solid transparent;

    transition: border-bottom 0.35s ease 0s;
`;

export default function WIPProjectListItem({ title, children }) {
    return (

            <StyledProjectContainer>

                <div>
                    <ProjectTitle>{title}</ProjectTitle>
                    <ProjectDescription>{children}</ProjectDescription>
                    <ComingSoon>COMING SOON</ComingSoon>
                </div>
            </StyledProjectContainer>

    );
}
